import Repeater_ResponsiveComponent from '../components/Repeater/viewer/skinComps/Responsive/Responsive.skin';


const Repeater_Responsive = {
  component: Repeater_ResponsiveComponent
};


export const components = {
  ['Repeater_Responsive']: Repeater_Responsive
};

